<template>
  <div class="container">
    <div class="breadcrumb m-b-25">
      <p class="topic_path m-0">
        <router-link :to="{ name: 'AccountSettings'}" class="ashen-link">{{
            $t("account.default.pageTitle")
          }}
        </router-link>
        <i class="fas fa-angle-right form-control-color"></i>
        {{ $t("medicalHistory.default.pageTitle") }}
      </p>
    </div>
    <div class="card text-first border-0">
      <div class="card-body max-800 mx-auto col-12">
        <div class="row">
          <h3 class="fw-bold m-b-10 mt-sm-2">{{ $t("medicalHistory.default.pageTitle") }}</h3>
          <div class="col">
            <div class="form-group">
              <label>{{ $t("medicalHistory.default.subtitle") }}</label>
              <div class="m-t-10">
                <div class="form-check ml-2 mx-auto m-t-10">
                  <input
                      id="anemia"
                      v-model="anemia"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="anemia">
                    {{ $t("medicalHistory.default.anemia") }}
                  </label>
                </div>
                <div class="form-check ml-2 mx-auto m-t-10">
                  <input
                      id="asthma"
                      v-model="asthma"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="asthma">
                    {{ $t("medicalHistory.default.asthma") }}
                  </label>
                </div>
                <div class="form-check ml-2 mx-auto m-t-10">
                  <input
                      id="heartDisease"
                      v-model="heartDisease"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="heartDisease">
                    {{ $t("medicalHistory.default.heartDisease") }}
                  </label>
                </div>
                <div class="form-check ml-2 mx-auto m-t-10">
                  <input
                      id="kidneyDisease"
                      v-model="kidneyDisease"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="kidneyDisease">
                    {{ $t("medicalHistory.default.kidneyDisease") }}
                  </label>
                </div>
                <div class="form-check ml-2 mx-auto m-t-10">
                  <input
                      id="epilepsy"
                      v-model="epilepsy"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="epilepsy">
                    {{ $t("medicalHistory.default.epilepsy") }}
                  </label>
                </div>
                <div class="form-check ml-2 mx-auto m-t-10">
                  <input
                      id="highBloodPressure"
                      v-model="highBloodPressure"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="highBloodPressure">
                    {{ $t("medicalHistory.default.highBloodPressure") }}
                  </label>
                </div>
                <div class="form-check ml-2 mx-auto m-t-10">
                  <input
                      id="diabetes"
                      v-model="diabetes"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="diabetes">
                    {{ $t("medicalHistory.default.diabetes") }}
                  </label>
                </div>
                <div class="form-check ml-2 mx-auto m-t-10">
                  <input
                      id="medicalHistoryOther"
                      v-model="other"
                      class="form-check-input"
                      type="checkbox"
                      @click="other!==other"
                  />
                  <label class="form-check-label" for="medicalHistoryOther">
                    {{ $t("medicalHistory.default.medicalHistoryOther") }}
                  </label>
                </div>

                <div v-if="other" class="form-check p-l-0 mx-auto">
                  <textarea id="otherText" v-model="otherText" class="form-control" rows="2"></textarea>
                  <div v-show="errors['otherText']" id="err_other" class="form-error">{{ errors['otherText'] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-t-5">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-1 order-sm-0">
            <button
                class="btn btn-light bg-white w-100 mw-100 ashen-link text-decoration-none mt-2 light border-0 custom-btn"
                @click="$router.push('account')">
              {{ $t("account.editWithPassword.btnCancel") }}
            </button>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-0 order-sm-1">
            <button class="btn btn-primary w-100 mw-100 ok btn-outline-light font-weight-bold mt-2 custom-btn"
                    @click="validateAndUpdate">
              {{ $t("account.editWithPassword.btnChange") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SetUpdateUser} from "../assets/jsipclient/index"
import Common from "../assets/jsipclient/common"
import {mapGetters} from "vuex"

export default {
  name: "EditMedicalHistory",
  data() {
    return {
      medicalHistory: '',
      anemia: null,
      asthma: null,
      heartDisease: null,
      kidneyDisease: null,
      epilepsy: null,
      highBloodPressure: null,
      diabetes: null,
      other: null,
      otherText: null,
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    createSetUpdateUser() {
      var params = new SetUpdateUser()
      params.initialUpdate = false
      params.authenticationPassword = ''
      params.firstName = ''
      params.firstNameUpdate = false
      params.lastName = ''
      params.lastNameUpdate = false
      params.password = ''
      params.passwordConfirm = ''
      params.passwordUpdate = false
      params.email = ''
      params.emailUpdate = false
      params.birthday = ''
      params.birthdayUpdate = false
      params.gender = 1;
      params.genderUpdate = false
      params.nationalityId = 1
      params.nationalityIdUpdate = false
      params.address = ''
      params.addressUpdate = false
      params.phoneNo = ''
      params.phoneNoUpdate = false
      params.hasCoronaVaccination = false
      params.latestCoronaVaccinationDate = ''
      params.coronaVaccinationUpdate = false
      params.latestPcrTestResult = 0
      params.latestPcrTestDate = ''
      params.latestPcrTestUpdate = false
      params.medicalHistoryUpdate = true
      params.anemia = this.anemia
      params.asthma = this.asthma
      params.heartDisease = this.heartDisease
      params.kidneyDisease = this.kidneyDisease
      params.epilepsy = this.epilepsy
      params.highBloodPressure = this.highBloodPressure
      params.diabetes = this.diabetes
      params.other = this.other
      params.otherText = this.otherText

      return params
    },
    async validateAndUpdate() {
      this.errors = [];

      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const setUpdateUser = this.createSetUpdateUser()
        const jsonObject = await api.usersUserIdUpdatePostAsync(user_id, setUpdateUser, 0)

        const message = this.$t("medicalHistory.default.successMessageUpdate")
        Common.showToast(message)
        this.$router.push({name: 'AccountSettings'});
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)
        this.anemia = jsonObject.anemia
        this.asthma = jsonObject.asthma
        this.heartDisease = jsonObject.heartDisease
        this.kidneyDisease = jsonObject.kidneyDisease
        this.epilepsy = jsonObject.epilepsy
        this.highBloodPressure = jsonObject.highBloodPressure
        this.diabetes = jsonObject.diabetes
        this.other = jsonObject.other
        this.otherText = jsonObject.otherText

      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    }
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
    this.getUser()
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.getUser()
    }
  }
}
</script>

<style lang="scss" scoped>
.fa-angle-right {
  width: 15px;
  height: auto;
  color: #888888;
}

@media only screen and (max-width: 576px) {
  .topic_path {
    display: none;
  }

  .border-top {
    border: 0 !important;
  }
}

</style>
